module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@6.25.0_gatsby-plugin-sharp@5.14.0_gatsby@5.14.2_babel-eslint@10.1.0_esli_7v6lfpfxsxgnxeqn4izw7aja2a/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-decap-cms@4.0.4_webpack@5.98.0/node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/Users/romanguivan/dev/agency-website/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.2_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__ecolog5zt7chbxjx4jngvsahku/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
